import {hexStringToByte} from "../utils/utils";

async function sign(input, secretCommitment, secret) {
    const rustWasm = await import("ergo-lib-wasm-browser")
    const inputJson = JSON.parse(input)
    let unsignedTxWasm=null
    const box = JSON.stringify(inputJson.inputBox)
    const inputBox = rustWasm.ErgoBox.from_json(box)
    const inputs = new rustWasm.ErgoBoxes(inputBox)
    const empty = rustWasm.ErgoBoxes.empty()
    const stateContext = rustWasm.ErgoStateContext.dummy()
    let hints = rustWasm.HintsBag.empty()
    let reducedTransaction=null
    if (inputJson.pk.length !== 0) {
        unsignedTxWasm = rustWasm.UnsignedTransaction.from_json(JSON.stringify(inputJson.unsignedTx))
        let pksArray = inputJson.pk
        const pks = pksArray.join()
        reducedTransaction = rustWasm.ReducedTransaction.from_unsigned_tx(unsignedTxWasm, inputs, empty, stateContext)
        const signedTx = rustWasm.Transaction.from_json(JSON.stringify(inputJson.tx))
        hints = reducedTransaction.bag_for_multi_sig(pks, secretCommitment, signedTx)
        inputJson.commitments.forEach((cmt) => {
            if(!pksArray.includes(cmt.a)){
                const real = rustWasm.CommitmentHintJson.from_json(JSON.stringify(cmt))
                hints.add_commitment(real)
            }
        })
    } else {
        unsignedTxWasm = rustWasm.UnsignedTransaction.from_json(JSON.stringify(inputJson.tx))

        reducedTransaction = rustWasm.ReducedTransaction.from_unsigned_tx(unsignedTxWasm, inputs, empty, stateContext)
        inputJson.unsignedTx=inputJson.tx
        inputJson.commitments.forEach((cmt) => {
            const real = rustWasm.CommitmentHintJson.from_json(JSON.stringify(cmt))
            hints.add_commitment(real)
        })
    }
    const sks = new rustWasm.SecretKeys()
    const secretKey = rustWasm.SecretKey.dlog_from_bytes(hexStringToByte(secret))
    sks.add(secretKey)
    const wallet = rustWasm.Wallet.from_secrets(sks)
    const transaction = wallet.sign_transaction_multi(stateContext, unsignedTxWasm, inputs, empty, hints)
    inputJson.tx = JSON.parse(transaction.to_json())
    let pk=JSON.parse(reducedTransaction.generate_commitment_for_first_input(secret))[0].pubkey.h
    inputJson.pk.push(pk)
    return JSON.stringify(inputJson, null, 2)


}

export {sign}
