import {FormControl, Grid, TextareaAutosize} from "@mui/material";
import React from "react";

function OutputBox(props) {
    let size=6
    if (props.size!=null){
        size=props.size
    }
    return (

        <Grid xs={12} md={size}   item>

            <FormControl fullWidth>
                <TextareaAutosize
                    aria-label="minimum height"
                    minRows={15}
                    maxRows={15}
                    placeholder="Output"
                    value={props.value}
                />
            </FormControl>
        </Grid>
    )
}

export default OutputBox
