import React, {useState} from "react";
import {Button, Card, FormControl, Grid, TextareaAutosize, TextField} from "@mui/material";
import {generateCommitment} from "../main/generateCommitment";
import OutputBox from "./OutputBox";
import {TextValidator, ValidatorForm} from 'react-material-ui-form-validator';
import {generateTx} from "../main/generateTx";

function Commitments() {
    const [tx, setTx] = useState("")
    const [secret, setSecret] = useState("")
    const [output, setOutput] = useState("")


    return (

        <ValidatorForm onSubmit={() => {
        }}>
            <Grid container spacing={2} p={3} style={{width: "100%"}}>

                <Grid item xs={12}>
                    <TextValidator
                        onChange={(e) => setSecret(e.target.value)}
                        label="Secret"
                        value={secret}
                        validators={['matchRegexp:^[a-fA-F0-9]{64}$']}
                        errorMessages={['Invalid Secret', 'Secret is not valid']}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextValidator
                        onChange={(e) => setTx(e.target.value)}
                        multiline
                        rows={10}
                        label="Tx"
                        value={tx}
                        fullWidth
                    />
                </Grid>
                <Grid container justifyContent="center" item>
                    <Button onClick={async () => {
                        const tmp = await generateCommitment(secret, tx)
                        setOutput(tmp)
                    }} variant="contained">Generate</Button>
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <TextField
                            multiline
                            rows={10}
                            label="Output"
                            value={output}
                            fullWidth
                        />
                    </FormControl>
                </Grid>
            </Grid>

        </ValidatorForm>
    )
}

export default Commitments
