
async function generateCommitment(secret,_input) {
    const rustWasm=await import("ergo-lib-wasm-browser")
    const stateContext=rustWasm.ErgoStateContext.dummy()
    const inputJson=JSON.parse(_input)
    const box=JSON.stringify(inputJson.inputBox)
    const tx=JSON.stringify(inputJson.tx)
    const unsignedTxWasm=rustWasm.UnsignedTransaction.from_json(tx)
    const input =rustWasm.ErgoBox.from_json(box)
    const inputs=new rustWasm.ErgoBoxes(input)
    const empty=rustWasm.ErgoBoxes.empty()
    const reducedTransaction=rustWasm.ReducedTransaction.from_unsigned_tx(unsignedTxWasm,inputs,empty,stateContext)
    const commitments=reducedTransaction.generate_commitment_for_first_input(secret)
    const [realCommitments,ownCommitments]=JSON.parse(commitments)
    inputJson.commitments.push(realCommitments)
    return JSON.stringify(inputJson,null,2) + JSON.stringify(ownCommitments,null,2)

}

export {generateCommitment}
