import {Button, Card, FormControl, Grid, TextareaAutosize, TextField} from "@mui/material";
import {generateTx} from "../main/generateTx";
import React, {useEffect, useState} from "react";
import OutputBox from "./OutputBox";
import {TextValidator, ValidatorForm} from 'react-material-ui-form-validator';
import {sign} from "../main/sign";


function UnsignedTx() {
    const [sender, setSender] = useState("")
    const [receiver, setReceiver] = useState("")
    const [value, setValue] = useState(1100000)
    const [box, setBox] = useState("")
    const [output, setOutput] = useState("")

    return (

        <ValidatorForm onSubmit={() => {
        }}>
            <Grid container spacing={2} p={3}>
                <Grid item xs={12} md={4}>
                    <TextValidator
                        onChange={(e) => setSender(e.target.value)}
                        label="Sender"
                        name="Sender"
                        value={sender}
                        validators={['matchRegexp:^[1-9A-HJ-NP-Za-km-z]+$']}
                        errorMessages={['Invalid P2SA', 'P2S address is not valid']}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={4}>

                    <TextValidator
                        onChange={(e) => setReceiver(e.target.value)}
                        label="Receiver"
                        value={receiver}
                        validators={['matchRegexp:^9[1-9A-HJ-NP-Za-km-z]{50}$']}
                        errorMessages={['Invalid P2PK', 'P2PK address is not valid']}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <TextValidator
                        onChange={(e) => setValue(e.target.value)}
                        label="Value"
                        value={value}
                        validators={['minNumber:1100000']}
                        errorMessages={['Invalid Value']}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextValidator
                        onChange={(e) => setBox(e.target.value)}
                        multiline
                        rows={10}
                        label="Boxes"
                        value={box}
                        fullWidth
                    />
                </Grid>
                <Grid container justifyContent="center" item>
                    <Button onClick={async () => {
                        const tmp = await generateTx(sender, receiver, value.toString(), box)
                        setOutput(tmp)
                    }} variant="contained">Generate</Button>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        multiline
                        rows={10}
                        label="Output"
                        value={output}
                        fullWidth
                    />
                </Grid>
            </Grid>
        </ValidatorForm>
    )
}

export default UnsignedTx
