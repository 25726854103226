import React, {useState} from "react";
import {Button, Card, FormControl, Grid, TextareaAutosize, TextField} from "@mui/material";
import {sign} from "../main/sign";
import {TextValidator, ValidatorForm} from 'react-material-ui-form-validator';


function Signing() {
    const [tx, setTx] = useState("")
    const [secret, setSecret] = useState("")
    const [commitment, setCommitment] = useState("")
    const [output, setOutput] = useState("")


    return (
        <ValidatorForm onSubmit={() => {
        }}>
            <Grid container spacing={2} p={3}>

                <Grid item xs={12}>
                    <TextValidator
                        onChange={(e) => setSecret(e.target.value)}
                        label="Secret"
                        value={secret}
                        validators={['matchRegexp:^[a-fA-F0-9]{64}$']}
                        errorMessages={['Invalid Secret', 'Secret is not valid']}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextValidator
                        onChange={(e) => setTx(e.target.value)}
                        multiline
                        rows={10}
                        label="Tx"
                        value={tx}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextValidator
                        onChange={(e) => setCommitment(e.target.value)}
                        multiline
                        rows={10}
                        label="SecretCommitment"
                        value={commitment}
                        fullWidth
                    />
                </Grid>
                <Grid container justifyContent="center" item>
                    <Button onClick={async () => {
                        const tmp = await sign(tx, commitment, secret)
                        setOutput(tmp)
                    }} variant="contained">Sign</Button>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        multiline
                        rows={10}
                        label="Output"
                        value={output}
                        fullWidth
                    />
                </Grid>
            </Grid>
        </ValidatorForm>

    )

}

export default Signing
