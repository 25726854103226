import './App.css';
import React, {useState, useEffect} from 'react';
import {codeTesting} from "./dev/codeTesting";
import {
    Box,
    Button,
    Card,
    CardContent,
    Container,
    FormControl,
    Grid,
    Tab,
    TextareaAutosize,
    TextField, Typography
} from "@mui/material";
import {generateTx} from "./main/generateTx";
import {generateCommitment} from "./main/generateCommitment";
import {sign} from "./main/sign";
import TabContext from '@mui/lab/TabContext';

import UnsignedTx from "./components/unsignedTx";
import Commitments from "./components/Commitments";
import Signing from "./components/Signing";
import * as PropTypes from "prop-types";
import {TabList, TabPanel} from "@mui/lab";
import OutputBox from "./components/OutputBox";


function App() {
    const [value, setValue] = React.useState('1');


    return (

        <Card>
            <CardContent>
                <TabContext value={value}>
                    <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                        <TabList onChange={(e, newValue) => {
                            setValue(newValue)
                        }} aria-label="lab API tabs example" variant="fullWidth">
                            <Tab label="Generate UnsignedTx" value="1"/>
                            <Tab label="Generate Commitment" value="2"/>
                            <Tab label="Sign Tx" value="3"/>
                        </TabList>
                    </Box>
                    <TabPanel value="1">
                        <Card>
                            <CardContent>
                                <Typography variant="h5" component="div">
                                    Generating unsigned transaction from input box
                                    <Typography variant="body2">
                                        <br/>
                                        <b>Sender</b> - P2SA of MultiSig contract
                                        <br/>
                                        <b>Receiver</b> - P2PK of fund Receiver
                                        <br/>
                                        <b>Value</b> - Amount of Erg you want to send in nErg
                                        <br/>
                                        <b>Boxes</b> - Input Box that you want to spend in JSON format.
                                        <br/>
                                        &nbsp; you should get the box
                                        from <i>https://api.ergoplatform.com/api/v1/boxes/p1</i> API
                                    </Typography>
                                </Typography>

                            </CardContent>
                        </Card>

                        <UnsignedTx/>
                    </TabPanel>
                    <TabPanel value="2">
                        <Card>
                            <CardContent>
                                <Typography variant="h5" component="div">
                                    Generating commitment
                                    <Typography variant="body2">
                                        <br/>
                                        After generating an unsigned transaction signer except the first signer should
                                        generate commitment, and save the secret commitment for when they want to sign
                                        the transaction; the secret commitment is appended at the end of the generated
                                        output in JSON format.
                                        <br/>
                                        <b>Secret</b> - Secret in hex format
                                        <br/>
                                        <b>Tx</b> - Generated output of the former step or former signer except first
                                        signer in present step
                                        <br/>
                                        <b>Output</b> - Take secret commitment for yourself and give the output to the
                                        next signer
                                        <br/>
                                    </Typography>
                                </Typography>

                            </CardContent>
                        </Card>
                        <Commitments/>
                    </TabPanel>
                    <TabPanel value="3">
                        <Card>
                            <CardContent>
                                <Typography variant="h5" component="div">
                                    Signing
                                    <Typography variant="body2">
                                        <br/>
                                        First signer that doesn't generate commitment should get tx generated in former
                                        step from last person and use it in this step
                                        <br/>
                                        <b>Secret</b> - Secret in hex format
                                        <br/>
                                        <b>Tx</b> - Generated output of the former step or former signer except first
                                        signer in present step
                                        <br/>
                                        <b>SecretCommitment</b> - All signer except first signer should fill this box
                                        with their secret commitment generated in former step
                                        <br/>
                                        <b>Output</b> - All signer except last signer should get this output to the next
                                        signer for generating their sign and the last signer after sign has the signed
                                        tx
                                    </Typography>
                                </Typography>

                            </CardContent>
                        </Card>

                        <Signing/>
                    </TabPanel>
                </TabContext>
            </CardContent>
        </Card>
    );
}

export default App;
