async function generateTx(_sender,_reciever,erg,_inputs) {
    const rustWasm=await import("ergo-lib-wasm-browser")
    const input =rustWasm.ErgoBox.from_json(_inputs)
    const inputs=new rustWasm.ErgoBoxes(input)
    const sender = rustWasm.Address.from_mainnet_str(_sender)
    const reciever=rustWasm.Address.from_mainnet_str(_reciever)
    const value = rustWasm.BoxValue.from_i64(
        rustWasm.I64.from_str(
            erg
        )
    )
    const valueFee= rustWasm.BoxValue.from_i64(
        rustWasm.I64.from_str(
            erg
        ).checked_add(rustWasm.I64.from_str(
            "1100000"
        ))
    )

    const outbox = new rustWasm.ErgoBoxCandidateBuilder(
        value,
        rustWasm.Contract.pay_to_address(reciever),
        0
    ).build()

    const txOutputs = new rustWasm.ErgoBoxCandidates(outbox)
    const fee = rustWasm.TxBuilder.SUGGESTED_TX_FEE()
    const minChangeValue = rustWasm.BoxValue.SAFE_USER_MIN()
    const boxSelector = new rustWasm.SimpleBoxSelector()
    const boxSelection = boxSelector.select(inputs, valueFee, new rustWasm.Tokens())
    const txBuilder = rustWasm.TxBuilder.new(
        boxSelection,
        txOutputs,
        0,
        fee,
        sender,
        minChangeValue
    )
    const unsignedTx = txBuilder.build()
    const output=JSON.parse(unsignedTx.to_json())
    const inputBox=JSON.parse(_inputs)
    const txSchema={
        tx:output,
        inputBox:inputBox,
        commitments:[],
        pk:[],
    }
    console.log(JSON.stringify(txSchema,null,2))
    return JSON.stringify(txSchema,null,2)

}

export {generateTx}
